import { useState, useEffect } from "react";
import {
  getAllKareBuddies,
  getPreSalesUsers,
} from "./../../services/mykare/userService";
import { getAgentData } from "../../services/commonService/commonService";
import {
  blacklist,
  getblacklist,
  getBulkTransferCount,
  getCircles,
  getKareBuddyDetail,
  getPreSalesAgentDetail,
  getTargetDetail,
  getTreatments,
  saveKareBuddyDetail,
  savePreSalesAgentDetail,
  saveTargetDetail,
  sendBulkTransfer,
} from "../../services/mykare/dataService";
import { getAllLocations } from "../../services/mykare/centerService";
import { saveSalesAgentDetail } from "../../services/mykare/dataService";
import { getSalesAgentDetail } from "../../services/mykare/dataService";
import moment from "moment";
import {
  getSources,
  getUserTrxnStatuses,
} from "../../services/mykare/enquiryService";
import {
  getBulkCount,
  sendMessage,
} from "../../services/mykare/messageService";
import { getAllBuddyCenters } from "../../services/mykare/centerService";
import { saveEventActivityLogs } from "../../util/EventActivity";
import { getAllPreLeadsTeam } from "../../services/mykare/PreLeadsService";
import { saveleaddigitalmanagement } from "../../services/mykare/dataService";
import { getLeadDigitalManagement } from "../../services/mykare/dataService";
function useSettings() {
  const [getDay, setGetDay] = useState([]);
  const [isTable, setIsTable] = useState(false);
  const [isNewLead, setIsNewLead] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const [agentData, setAgentData] = useState([]);
  const [preAgentData, setPreAgentData] = useState([]);
  const [buddyDetails, setBudyDetails] = useState(null);
  const [blackNumber, setBlackNumber] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const countryCodes = [
    { id: 1, name: "+91" },
    { id: 2, name: "+880" },
  ];
  const [locationtData, setLocationData] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [circles, setCircles] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [centerNames, setCenterNames] = useState([]);
  const [circleNames, setCircleNames] = useState([]);
  const [salesAgentName, setSalesAgentName] = useState("");
  const [preSalesAgentName, setPreSalesAgentName] = useState("");
  const [kareBuddyName, setBuddyName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [targetDetail, setTargetDetail] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchDigitalLeadManagement,setSearchDigitalLeadManagement]=useState()

  const [assignedData, setAssignedData] = useState({
    chooseDate: "All",
    fromDate: new Date(),
    toDate: new Date(),
  });
  // Create Campagin
  const statusLists = [
    { value: 7, label: "DNP 1" },
    { value: 8, label: "DNP 2" },
    { value: 9, label: "DNP 3" },
    { value: 10, label: "DNP 4" },
    { value: 11, label: "DNP 5" },
    { value: 12, label: "Not Interested" },
    { value: 14, label: "Followup 3" },
  ];

  const [selectedCampStatus, setCampStatus] = useState([]);
  const [selectedCampStatusId, setCampStatusId] = useState([]);

  const [activeTab, setTabActive] = useState({
    isLeadAssignActive: false,
    isBulkAssignActive: false,
    isBlackListActive: false,
    isBulkMessageActive: false,
    isAssignBuddyActive: false,
    isTargetActive: false,
    isPreLeadActive: false,
    isDigitalLeadActive: false,
  });

  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "Yesterday",
    "Choose Date",
  ]);
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");

  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [selectedCircleIds, setSelectedCircleIds] = useState([0]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCenterIds, setSelectedCenterIds] = useState([0]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectedCircles, setSelectedCircles] = useState([]);
  const [statusId, setStatusId] = useState(0);
  const [status, setStatus] = useState(null);
  const [selectedSource, setSelectedSource] = useState("");
  const [sources, setSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [blackListData, setBlackListData] = useState(null);
  const [statusList, setStatusList] = useState([
    { id: 7, name: "DNP 1" },
    { id: 14, name: "Followup 3" },
    { id: 31, name: "Surgery Suggested" },
    { id: 22, name: "Out Of City" },
    { id: 32, name: "Fund Issue" },
    { id: 16, name: "Workable Exhausted" },
    { id: 15, name: "Not Interested" },
  ]);
  const [mode, setMode] = useState("");

  const messageMode = ["WHATSAPP", "SMS"];

  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });

  const [preAssignedUser, setPreAssignedUser] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [assignedTargetUser, setAssignedTargetUser] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [assignedActiveUser, setAssignedActiveUser] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });

  const [presaleActiveUser, setPresaleActiveUser] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });

  const [assignedFrom, setAssignedFrom] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [assignedLeadTo, setAssignedLeadTo] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [assignedTo, setAssignedTo] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [assignedBuddy, setAssignedBuddy] = useState({
    list: [{ id: 0, name: "select", code: "select" }],
    selected: null,
    selectedCode: null,
  });

  const [monthList, setMonthList] = useState(moment.months());
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));
  const [searchMonth, setSearchMonth] = useState(moment().format("MMMM"));
  const [searchDigitalMonth, setSearchDigitalMonth]= useState("")
  const selectedYear = moment().format("YYYY");
  const [ipTarget, setIpTarget] = useState();
  const [opTarget, setOpTarget] = useState();
  const [revenue, setRevenue] = useState();
  const [budgetedSpent, setBudgetedSpent] = useState();
  const [numberOfLeads, setNumberOfLeads] = useState();

  const defaultDays = [
    {
      key: 0,
      day: "SUNDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 1,
      day: "MONDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 2,
      day: "TUESDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 3,
      day: "WEDNESDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 4,
      day: "THURSDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 5,
      day: "FRIDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
    {
      key: 6,
      day: "SATURDAY",
      startTime: "12:00 AM",
      endTime: "11:59 PM",
      status: true,
    },
  ];
  const [selectDays, setSelectDays] = useState(defaultDays); 
  const [leadDigitalManagememt, setLeadDigitalManagement]= useState( {
       locationId:"",
       treatmentId:"",
       month:"",
       sourceType:"FACEBOOK",
       leadsNeed:"",
       spent:"",
})

  useEffect(() => {
    handleTab(0);
  }, []);

  useEffect(() => {
    loadTreatments();
    loadLocations();
    loadAssignedUsers();
    loadAssignedActiveUsers();
    loadPreAssignedUsers();
    loadAllKareBuddies();
    loadStatus();
    loadSources();
    loadCircles();
    loadCenters();
  }, []);

  useEffect(() => {
    searchTargetDetails();
  }, [searchMonth, selectedYear]);

  const handleTab = (selected) => {
    if (selected === 0) {
      setTabActive((s) => ({
        isLeadAssignActive: true,
        isBulkAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
      setIsTable(false);
      setSelectedLocations([]);
      setSelectedLocationIds([]);
      setSelectedCircleIds([]);
      setSelectedCircles([]);
      setSelectedSource([]);
      setSelectedSourceIds([]);
      setSelectedTreatmentIds([]);
      setSelectedTreatments([]);
      setSelectDays(defaultDays);
    } else if (selected === 1) {
      setTabActive((s) => ({
        isBulkAssignActive: true,
        isLeadAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
    } else if (selected === 2) {
      setTabActive((s) => ({
        isBlackListActive: false,
        isLeadAssignActive: false,
        isBulkAssignActive: false,
        isBulkMessageActive: true,
        isAssignBuddyActive: false,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
      setSelectedLocations([]);
      setSelectedTreatments([]);
    } else if (selected === 3) {
      getBlackLists();
      setTabActive((s) => ({
        isBulkAssignActive: false,
        isLeadAssignActive: false,
        isBlackListActive: true,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
    } else if (selected === 4) {
      setTabActive((s) => ({
        isBulkAssignActive: false,
        isLeadAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: true,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
      setIsTable(false);
      setSelectedLocations([]);
      setSelectedLocationIds([]);
      setSelectedCenterIds([]);
      setSelectedCenters([]);
      setBuddyName("");
      setSelectDays(defaultDays);
    } else if (selected === 5) {
      setTabActive((s) => ({
        isLeadAssignActive: false,
        isBulkAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isUserRole: true,
        isTargetActive: false,
        isPreLeadActive: false,
      }));
    } else if (selected === 6) {
      setTabActive((s) => ({
        isLeadAssignActive: false,
        isBulkAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isUserRole: false,
        isTargetActive: true,
        isPreLeadActive: false,
      }));
    } else if (selected === 7) {
      setTabActive((s) => ({
        isLeadAssignActive: false,
        isBulkAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isUserRole: false,
        isTargetActive: false,
        isPreLeadActive: true,
      }));
    } else if (selected === 8) {
      setTabActive((s) => ({
        isLeadAssignActive: false,
        isBulkAssignActive: false,
        isBlackListActive: false,
        isBulkMessageActive: false,
        isAssignBuddyActive: false,
        isUserRole: false,
        isTargetActive: false,
        isDigitalLeadActive: true,
      }));
    }
  };

  const handleStatus = (status, id) => {
    setStatus(status);
    setStatusId(id);
  };

  const handleMode = (mode, id) => {
    setMode(mode);
  };

  const loadSources = async () => {
    try {
      const response = await getSources();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setSources(data);
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setStatusData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadAssignedUsers = async () => {
    try {
      getAgentData(true).then((res) => {
        setAssignedUser((s) => ({ ...s, list: res.data.users }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadPreAssignedUsers = async () => {
    try {
      getAllPreLeadsTeam(true).then((res) => {
        setPreAssignedUser((s) => ({ ...s, list: res?.data?.users }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadAssignedActiveUsers = async () => {
    try {
      getAgentData(false).then((res) => {
        setAssignedActiveUser((s) => ({ ...s, list: res.data.users }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadAllKareBuddies = async () => {
    try {
      getAllKareBuddies().then((res) => {
        setAssignedBuddy((s) => ({ ...s, list: res.data }));
      });
    } catch (error) {}
  };

  const handlePreAssignedToChange = (assignedCode, assignedName) => {
    setPreAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
    setPreSalesAgentName(assignedName);
    if (activeTab.isPreLeadActive) {
      getPreSalesAgentData(assignedCode);
    }
  };

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
    setSalesAgentName(assignedName);
    if (activeTab.isLeadAssignActive) {
      getSalesAgentData(assignedCode);
    }
  };
  const handleAssignedLeadFromChange = (assignedCode, assignedName) => {
    setAssignedFrom((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
    setSalesAgentName(assignedName);
    if (activeTab.isLeadAssignActive) {
      getSalesAgentData(assignedCode);
    }
  };
  const handleTargetAssignedUser = (assignedCode, assignedName) => {
    setAssignedTargetUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };
  const handleAssignedLeadToChange = (assignedCode, assignedName) => {
    setAssignedLeadTo((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };
  const handleAssignedBuddyToChange = (assignedCode, assignedName) => {
    setAssignedBuddy((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
    setBuddyName(assignedName);
    if (activeTab.isAssignBuddyActive) {
      getKareBuddyData(assignedCode);
    }
  };

  const loadTreatments = async () => {
    try {
      const response = await getTreatments();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setTreatmentData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocationData(data);
     
    } catch (error) {
      console.log("error", error);
    }
  };
  const loadCenters = async () => {
    try {
      const response = await getAllBuddyCenters();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setCenterData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadCircles = async () => {
    try {
      const response = await getCircles();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setCircles(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSource(
        selectedSource.length === sources.length ? [] : sources
      );
      ids = sources.map((item) => item.id);
      setSelectedSourceIds(
        selectedSource.length === sources.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sources.length) {
        value = sources;
      }
      setSelectedSource(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSource(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
  };
  const handleSelecCampStatus = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setCampStatus(
        selectedCampStatus.length === statusLists.length ? [] : statusLists
      );
      ids = statusLists.map((item) => item.id);
      setCampStatusId(
        selectedCampStatus.length === statusLists.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusLists.length) {
        value = statusLists;
      }
      setCampStatus(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setCampStatusId(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setCampStatus(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setCampStatusId(ids);
    }
  };

  const handleDayTime = (key, type, value) => {
    var selectedDay = selectDays[key];
    if (type === "startTime") {
      selectedDay.startTime = value.target.value;
    } else if (type === "endTime") {
      selectedDay.endTime = value.target.value;
    } else if (type === "status") {
      selectedDay.status = value;
    }
    const selectedDays = selectDays.map((day) =>
      day.key === key ? selectedDay : day
    );
    setSelectDays(selectedDays);
  };

  // const handleClick = (key, status, value) => {
  //   setSelectDays((s) => [...s, { status: !status }]);
  //   if (value === "ON") {
  //     return status;
  //   }
  //   if (value === "OFF") {
  //     return status;
  //   }
  // };

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  const handleSelectTreatments = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      ids = treatmentData.map((item) => item.value);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatmentData.length) {
        value = treatmentData;
      }
      setSelectedTreatments(value);
      ids = value.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedTreatments(data);
      ids = data.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    }
  };

  const handleSelectLocations = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocations(
        selectedLocations.length === locationtData.length ? [] : locationtData
      );
      ids = locationtData.map((item) => item.value);
      setSelectedLocationIds(
        selectedLocations.length === locationtData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locationtData.length) {
        value = locationtData;
      }
      setSelectedLocations(value);
      ids = value.map((item) => item.value);
      const locationName = value.map((item) => item.label);
      setLocationNames(locationName);
      setSelectedLocationIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocations(data);
      ids = data.map((item) => item.value);
      setSelectedLocationIds(ids);
    }
  };
  const handleSelectCenters = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedCenters(
        selectedCenters.length === centerData.length ? [] : centerData
      );
      ids = centerData.map((item) => item.value);
      setSelectedCenterIds(
        selectedCenters.length === centerData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === centerData.length) {
        value = centerData;
      }
      setSelectedCenters(value);
      ids = value.map((item) => item.value);
      const centerNames = value.map((item) => item.label);
      setCenterNames(centerNames);
      setSelectedCenterIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedCenters(data);
      ids = data.map((item) => item.value);
      setSelectedCenterIds(ids);
    }
  };
  const handleSelectCircles = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedCircles(
        selectedCircles.length === circles.length ? [] : circles
      );
      ids = circles.map((item) => item.value);
      setSelectedCircleIds(
        selectedCircles.length === circles.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === circles.length) {
        value = circles;
      }
      setSelectedCircles(value);
      ids = value.map((item) => item.value);
      const locationName = value.map((item) => item.label);
      setCircleNames(locationName);
      setSelectedCircleIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedCircles(data);
      ids = data.map((item) => item.value);
      setSelectedCircleIds(ids);
    }
  };

  const saveKareBuddy = async () => {
    const postData = {
      // centerIds: selectedCenterIds,
      kareBuddyCode: assignedBuddy.selectedCode,
      locationIds: selectedLocationIds,
      scheduleDays: selectDays,
    };
    setIsTable(false);
    try {
      if (
        assignedBuddy.selectedCode &&
        selectedLocationIds.length !== 0 &&
        // selectedCenterIds.length !== 0 &&
        selectDays.length !== 0
      ) {
        const response = await saveKareBuddyDetail(postData);
        if (response.status == 200) {
          getKareBuddyData(assignedBuddy.selectedCode);
          const requset = {
            type: "Update Buddy Management",
            activities: [postData],
          };
          saveEventActivityLogs(requset);
          setAssignedBuddy((s) => ({
            ...s,
            selectedCode: null,
            selected: null,
          }));
          alert("Successfully Saved");
          setIsSave(true);
        }
      } else {
        alert("Please select all fields");
        setIsSave(true);
      }
    } catch (error) {
      console.log("==========", error);
    }
  };

  const saveTargetDetails = async () => {
    const postData = {
      teamCode: assignedTargetUser.selectedCode,
      ipTarget: Number(ipTarget),
      opTarget: Number(opTarget),
      revenue: Number(revenue),
      // budgetSpent: budgetedSpent,
      budgetedLeads: Number(numberOfLeads),
      monthValue: monthList.findIndex((m) => m === selectedMonth) + 1,
      yearValue: Number(selectedYear),
    };

    setIsTable(false);
    try {
      if (
        assignedTargetUser.selectedCode &&
        ipTarget !== null &&
        opTarget !== null &&
        revenue !== null &&
        budgetedSpent !== null &&
        numberOfLeads !== null
      ) {
        const response = await saveTargetDetail(postData);
        if (response.status === 200) {
          getSalesAgentData(assignedUser.selectedCode);
          const requset = {
            type: "Save Target Management",
            activities: [postData],
          };
          saveEventActivityLogs(requset);
          setAssignedUser((s) => ({
            ...s,
            selectedCode: null,
            selected: null,
          }));
          alert("Successfully Saved");
          setIsSave(true);
        }
      } else {
        alert("Please select all fields");
        setIsSave(true);
      }
    } catch (error) {
      console.log("==========", error);
    }
  };

  const searchTargetDetails = async () => {
    setIsTable(false);
    try {
      if (searchMonth && selectedYear) {
        getTargetDetail(
          monthList.findIndex((m) => m === searchMonth) + 1,
          selectedYear
        ).then((res) => {
          if (res.data) {
            setTargetDetail(res.data.teamTargets);
          } else {
            setTargetDetail([]);
          }
        });
      }
    } catch (error) {
      console.log("==========", error);
    }
  };

  
  const savePreAgentsDetails = async () => {
    const postData = {
      teamCode: preAssignedUser.selectedCode,
      locationIds: selectedLocationIds,
      treatmentIds: selectedTreatmentIds,
      scheduleDays: selectDays,
      sourceIds: selectedSourceIds,
      circleIds: selectedCircleIds,
    };
    console.log("======savePreAgentsDetails========", postData);
    setIsTable(false);
    try {
      if (
        preAssignedUser.selectedCode &&
        selectedLocationIds.length !== 0 &&
        selectedTreatmentIds.length !== 0 &&
        selectDays.length !== 0
      ) {
        const response = await savePreSalesAgentDetail(postData);
        if (response.status === 200) {
          getPreSalesAgentData(preAssignedUser.selectedCode);
          const requset = {
            type: "Update Pre Lead Management",
            activities: [postData],
          };
          saveEventActivityLogs(requset);
          setPreAssignedUser((s) => ({
            ...s,
            selectedCode: null,
            selected: null,
          }));
          alert("Successfully Saved");
          setIsSave(true);
        }
      } else {
        alert("Please select all fields");
        setIsSave(true);
      }
    } catch (error) {
      console.log("==========", error);
    }
  };

  const searchDigitalLead = async()=> {
    if (searchDigitalMonth == "") {
      alert("please provide month")
    }
    try {
    
        const searchresult = await getLeadDigitalManagement(searchDigitalMonth)
        if (searchresult) {
         
          setSearchDigitalLeadManagement(searchresult)
        }
        else {
          setSearchDigitalLeadManagement([])
        }

      
    }
    catch (error) {
      console.log("error")
    }
  }

  const saveLeadDigitalManagements = async() => {
    // const postdata= {
    //   locationIds: selectedLocationIds,
    //   treatmentIds: selectedTreatmentIds,
    //   scheduleDays: selectDays,
    //   sourceIds: selectedSourceIds,
    //   circleIds: selectedCircleIds,
    // }
  
    if(leadDigitalManagememt.month == " " || leadDigitalManagememt.sourceType == ""){
      alert("please fill required field")
    }
    const response = await saveleaddigitalmanagement(leadDigitalManagememt)
    
    if(response.status == 200){
      alert("Saved Successfully")
    }
    else {
      alert("Something went wrong")
    }


  }

  const saveAgentsDetails = async () => {
    const postData = {
      teamCode: assignedUser.selectedCode,
      locationIds: selectedLocationIds,
      treatmentIds: selectedTreatmentIds,
      scheduleDays: selectDays,
      sourceIds: selectedSourceIds,
      circleIds: selectedCircleIds,
    };
    setIsTable(false);
    try {
      if (
        assignedUser.selectedCode &&
        selectedLocationIds.length !== 0 &&
        selectedTreatmentIds.length !== 0 &&
        selectDays.length !== 0
      ) {
        const response = await saveSalesAgentDetail(postData);
        if (response.status === 200) {
          getSalesAgentData(assignedUser.selectedCode);
          const requset = {
            type: "Update Lead Management",
            activities: [postData],
          };
          saveEventActivityLogs(requset);
          setAssignedUser((s) => ({
            ...s,
            selectedCode: null,
            selected: null,
          }));
          alert("Successfully Saved");
          setIsSave(true);
        }
      } else {
        alert("Please select all fields");
        setIsSave(true);
      }
    } catch (error) {
      console.log("==========", error);
    }
  };

  const getPreSalesAgentData = async (assignedCode) => {
    try {
      const response = await getPreSalesAgentDetail(assignedCode);
      if (response.status === 200) {
        setPreAgentData(response.data);
        if (response.data.scheduleDays.length !== 0) {
          setSelectDays([]);
          response.data.scheduleDays.map((val, i) => {
            setSelectDays((s) => [
              ...s,
              {
                key: i,
                startTime: val.startTime,
                endTime: val.endTime,
                status: val.status,
                day: val.day,
              },
            ]);
          });
        }
        if (
          response.data.locations.length !== 0 ||
          response.data.treatments.length !== 0
        ) {
          var data = [{ id: 0, name: "ALL" }];
          var defaultData = [{ id: 0, name: "ALL" }];
          var defaultCircle = [{ id: 0, name: "ALL" }];
          var defaultSource = [{ id: 0, name: "ALL" }];

          if (circles.length == response.data.telecomCircles.length) {
            defaultCircle.push.apply(
              defaultCircle,
              response.data.telecomCircles
            );
          } else {
            defaultCircle = response.data.telecomCircles;
          }

          if (sources.length == response.data.sources.length) {
            defaultSource.push.apply(defaultSource, response.data.sources);
          } else {
            defaultSource = response.data.sources;
          }

          if (locationtData.length == response.data.locations.length) {
            data.push.apply(data, response.data.locations);
          } else {
            data = response.data.locations;
          }

          const locations = data.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });
          const circle = defaultCircle.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });

          const source = defaultSource.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });

          if (treatmentData.length == response.data.treatments.length) {
            defaultData.push.apply(defaultData, response.data.treatments);
          } else {
            defaultData = response.data.treatments;
          }
          const treatments = defaultData.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });
          setSelectedCircles(circle);
          setSelectedTreatments(treatments);
          setSelectedLocations(locations);
          setSelectedSource(source);
          const ids = response.data.locations.map((item) => item.id);
          setSelectedLocationIds(ids);
          const treatmentIds = response.data.treatments.map((item) => item.id);
          setSelectedTreatmentIds(treatmentIds);
          const circleIds = response.data.telecomCircles.map((item) => item.id);
          setSelectedCircleIds(circleIds);
          const sourceIds = response.data.sources.map((item) => item.id);
          setSelectedSourceIds(sourceIds);
        }
      }
    } catch (error) {
      console.log("=====error=====", error);
    }
  };

  const getSalesAgentData = async (assignedCode) => {
    try {
      const response = await getSalesAgentDetail(assignedCode);
      if (response.status === 200) {
        setAgentData(response.data);
        if (response.data.scheduleDays.length !== 0) {
          setSelectDays([]);
          response.data.scheduleDays.map((val, i) => {
            setSelectDays((s) => [
              ...s,
              {
                key: i,
                startTime: val.startTime,
                endTime: val.endTime,
                status: val.status,
                day: val.day,
              },
            ]);
          });
        }
        if (
          response.data.locations.length !== 0 ||
          response.data.treatments.length !== 0
        ) {
          var data = [{ id: 0, name: "ALL" }];
          var defaultData = [{ id: 0, name: "ALL" }];
          var defaultCircle = [{ id: 0, name: "ALL" }];
          var defaultSource = [{ id: 0, name: "ALL" }];

          if (circles.length == response.data.telecomCircles.length) {
            defaultCircle.push.apply(
              defaultCircle,
              response.data.telecomCircles
            );
          } else {
            defaultCircle = response.data.telecomCircles;
          }

          if (sources.length == response.data.sources.length) {
            defaultSource.push.apply(defaultSource, response.data.sources);
          } else {
            defaultSource = response.data.sources;
          }

          if (locationtData.length == response.data.locations.length) {
            data.push.apply(data, response.data.locations);
          } else {
            data = response.data.locations;
          }

          const locations = data.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });
          const circle = defaultCircle.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });

          const source = defaultSource.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });

          if (treatmentData.length == response.data.treatments.length) {
            defaultData.push.apply(defaultData, response.data.treatments);
          } else {
            defaultData = response.data.treatments;
          }
          const treatments = defaultData.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });
          setSelectedCircles(circle);
          setSelectedTreatments(treatments);
          setSelectedLocations(locations);
          setSelectedSource(source);
          const ids = response.data.locations.map((item) => item.id);
          setSelectedLocationIds(ids);
          const treatmentIds = response.data.treatments.map((item) => item.id);
          setSelectedTreatmentIds(treatmentIds);
          const circleIds = response.data.telecomCircles.map((item) => item.id);
          setSelectedCircleIds(circleIds);
          const sourceIds = response.data.sources.map((item) => item.id);
          setSelectedSourceIds(sourceIds);
        }
      }
    } catch (error) {
      console.log("=====error=====", error);
    }
  };

  const getKareBuddyData = async (assignedCode) => {
    try {
      const response = await getKareBuddyDetail(assignedCode);
      if (response.status === 200) {
        setBudyDetails(response.data);
        if (response.data.scheduleDays.length !== 0) {
          setSelectDays([]);
          response.data.scheduleDays.map((val, i) => {
            setSelectDays((s) => [
              ...s,
              {
                key: i,
                startTime: val.startTime,
                endTime: val.endTime,
                status: val.status,
                day: val.day,
              },
            ]);
          });
        }
        if (
          response.data.locations.length !== 0 ||
          response.data.centers.length !== 0
        ) {
          var data = [{ id: 0, name: "ALL" }];
          var defaultData = [{ id: 0, name: "ALL" }];
          if (locationtData.length == response.data.locations.length) {
            data.push.apply(data, response.data.locations);
          } else {
            data = response.data.locations;
          }

          const locations = data.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });

          if (centerData.length == response.data.centers.length) {
            defaultData.push.apply(defaultData, response.data.centers);
          } else {
            defaultData = response.data.centers;
          }
          const centers = defaultData.map((val, i) => {
            return { id: i, value: val.id, label: val.name };
          });
          setSelectedCenters(centers);
          setSelectedLocations(locations);
          const ids = response.data.locations.map((item) => item.id);
          setSelectedLocationIds(ids);
          const centersId = response.data.centers.map((item) => item.id);
          setSelectedCenterIds(centersId);
        }
      }
    } catch (error) {
      console.log("=====error=====", error);
    }
  };

  const handleSelectStatuses = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      ids = statusData.map((item) => item.value);
      setSelectedStatusIds(
        selectedStatuses.length === statusData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusData.length) {
        value = statusData;
      }
      setSelectedStatuses(value);
      ids = value.map((item) => item.value);
      setSelectedStatusIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedStatuses(data);
      ids = data.map((item) => item.value);
      setSelectedStatusIds(ids);
    }
  };

  const assignLead = async (postData) => {
    postData.toNewLead = isNewLead;
    const response = await sendBulkTransfer(postData);
    if (response.status === 200) {
      alert(`Lead has sucessfully assigned to ${assignedLeadTo.selected}`);
      const requset = {
        type: "Send BulkTransfer",
        activities: [postData],
      };
      saveEventActivityLogs(requset);
    }
  };

  const bulkAssign = async () => {
    const postData = {
      assignTo: assignedLeadTo.selectedCode,
      assignFrom: assignedFrom.selectedCode,
      locationIds: selectedLocationIds,
      treatmentIds: selectedTreatmentIds,
      dateFilter: assignedData.chooseDate,
      statusIds: selectedStatusIds,
      sourceIds: selectedSourceIds,
      fromDate: moment(assignedData.fromDate).format().split("+")[0],
      toDate: moment(assignedData.toDate).format().split("+")[0],
    };
    if (
      postData.assignTo &&
      postData.assignFrom &&
      postData.locationIds.length !== 0 &&
      postData.treatmentIds.length &&
      postData.statusIds.length &&
      postData.sourceIds.length &&
      postData.dateFilter
    ) {
      const countResponse = await getBulkTransferCount(postData);
      if (countResponse.status === 200 && countResponse.data) {
        window.confirm(
          `Showing total ${selectedStatuses.map((val) => val.label)} counts : ${
            countResponse.data.count
          } \n Are you sure you want to assign this Leads to ${
            assignedLeadTo.selected
          }?`
        ) && assignLead(postData);
      }
    } else {
      alert("Fill all fields to Transfer !!");
    }
  };
  const editAgentsDetails = () => {};
  const days = [
    { id: 1, days: "Sunday" },
    { id: 2, days: "Monday" },
    { id: 3, days: "Tuesday" },
    { id: 4, days: "Wednesday" },
    { id: 5, days: "Thursday" },
    { id: 6, days: "Friday" },
    { id: 7, days: "Saturday" },
  ];

  const tabNames = ["Lead Assigned", "Bulk Assign"];

  const time = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "11:59 PM",
  ];

  const getBlackLists = async () => {
    const respone = await getblacklist();
    if (respone.data) {
      setBlackListData(respone.data);
    }
  };

  const saveBlackDetails = async () => {
    console.log("save  Black");
    if (blackNumber) {
      let code = countryCode ? countryCode.name : "+91";
      const postData = {
        phoneNumber: code + blackNumber,
      };
      try {
        const response = await blacklist(postData);
        if (response.status === 200) {
          getBlackLists();
          const requset = {
            type: "User Black Listed",
            activities: [postData],
          };
          saveEventActivityLogs(requset);
          alert("User Blacklisted.!");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      alert("Please fill all the Fields");
    }
  };

  const sendBulkMessage = async () => {
    const startDate = moment(searchEnquiryFromDate).format().split("T")[0];
    const endDate = moment(searchEnquiryToDate).format().split("T")[0];
    const postData = {
      userTrxnStatusId: statusId,
      fromDate: startDate,
      toDate: endDate,
      messageMode: mode,
    };
    getBulkCount(postData)
      .then((res) => {
        console.log("========res==", res);
        if (res.count === 0) {
          alert("No Leads found");
        } else {
          if (
            window.confirm(
              "Found " + res.count + " Leads to send Message, Please confirm "
            )
          ) {
            sendMessage(postData).then((res) => {
              alert("Sent messsage successfully!");
            });
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return {
    messageMode,
    selectedYear,
    selectedMonth,
    setSelectedMonth,
    searchMonth,
    setSearchMonth,
    searchDigitalMonth,
    setSearchDigitalMonth,
    monthList,
    setOpTarget,
    setIpTarget,
    setRevenue,
    setBudgetedSpent,
    setNumberOfLeads,
    isTable,
    isSave,
    getDropdownButtonLabel,
    selectedOptions,
    setSelectedOptions,
    treatmentData,
    locationtData,
    setSelectedTreatments,
    handleSelectTreatments,
    selectedTreatments,
    assignedUser,
    days,
    time,
    setSelectDays,
    selectDays,
    handleDayTime,
    // handleClick,
    handleSelectLocations,
    setSelectedLocations,
    selectedLocations,
    handleAssignedToChange,
    saveAgentsDetails,
    editAgentsDetails,
    setGetDay,
    setIsTable,
    setIsSave,
    agentData,
    preAgentData,
    salesAgentName,
    preSalesAgentName,
    kareBuddyName,
    locationNames,
    activeTab,
    handleTab,
    tabNames,
    enquiryDateFilter,
    searchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryFromDate,
    setSearchEnquiryToDate,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    bulkAssign,
    setAssignedData,
    assignedData,
    selectedStatusIds,
    selectedStatuses,
    statusData,
    handleSelectStatuses,
    setSelectedStatuses,
    setIsNewLead,
    isNewLead,
    countryCodes,
    countryCode,
    setCountryCode,
    blackNumber,
    setBlackNumber,
    saveBlackDetails,
    status,
    handleStatus,
    statusList,
    sendBulkMessage,
    selectedSource,
    setSelectedSource,
    sources,
    handleSelectSources,
    setSelectedSource,
    circles,
    handleSelectCircles,
    setSelectedCircles,
    selectedCircles,
    centerData,
    selectedCenterIds,
    selectedCenters,
    setSelectedCenterIds,
    setSelectedCenters,
    handleSelectCenters,
    assignedBuddy,
    setAssignedBuddy,
    handleAssignedBuddyToChange,
    saveKareBuddy,
    buddyDetails,
    blackListData,
    setStatusList,
    assignedTo,
    assignedFrom,
    assignedLeadTo,
    handleAssignedLeadFromChange,
    handleAssignedLeadToChange,
    statusLists,
    handleSelecCampStatus,
    selectedCampStatus,
    setCampStatus,
    setAssignedActiveUser,
    assignedActiveUser,
    saveTargetDetails,
    assignedTargetUser,
    preAssignedUser,
    savePreAgentsDetails,
    setAssignedTargetUser,
    handleTargetAssignedUser,
    handlePreAssignedToChange,
    searchTargetDetails,
    targetDetail,
    handleMode,
    mode,
    setMode,
    leadDigitalManagememt,
    setLeadDigitalManagement,
    saveLeadDigitalManagements,
    searchDigitalLead,
    setSearchDigitalLeadManagement,
    searchDigitalLeadManagement,
    
    
    
    
   
    
  };
}
export default useSettings;
